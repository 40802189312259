import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthService from '../services/auth';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

// import io from 'socket.io-client';
import { BASE_URL } from '../constants';
import { CircularProgress, Modal } from '@mui/material';
import OnDemandScrapeDisplay from '../Components/OnDemandScrapeDisplay';

// const socket = io(BASE_URL);

// const theme = createTheme();
// const modalStyle = {
//   // position: 'absolute',
//   // top: '50%',
//   // left: '50%',
//   margin: '0 auto',
//   // transform: 'translate(-50%, -50%)',
//   width: '90%',
//   minHeight: 'calc(100vh - 10px)',
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };
export default function AdhocScrape() {
  // const [startDate, setStartDate] = React.useState(moment())
  // const [logs, setLogs] = React.useState([])
  // const [endDate, setEndDate] = React.useState(moment())
  // const [scraperHealth, setScraperHealth] = React.useState(null)
  // const [onDemandScraper, setOnDemandScraper] = React.useState(null)
  // const [isConnected, setIsConnected] = React.useState(socket.connected);
  // const [lastPong, setLastPong] = React.useState(null);
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   const origin = data.get('origin');
  //   const destination = data.get('destination');
  //   console.log('lalal', {
  //     origin, destination, startDate, endDate
  //   })
  //   const _startDate = startDate || moment()
  //   const _endDate = endDate || moment()
  //   if (!origin || !destination) {
  //     alert('origin & destination are required')
  //     return;
  //   }
  //   const dateDiffs = _endDate.diff(_startDate, 'hours')
  //   console.log(dateDiffs)
  //   if (dateDiffs === 0 || dateDiffs < 1) {
  //     alert('End date should be greater than start date')
  //     return;
  //   }
  //   const payload = {
  //     origin,
  //     destination,
  //     startDate: _startDate.format('YYYY-MM-DD'),
  //     endDate: _endDate.format('YYYY-MM-DD'),
  //   }
  //   socket.emit('route-prices', { origin, destination, type: 'ECONOMY', isOnDemand: true, ...payload }, (res) => {
  //     console.log('callback received', res)
  //     if (res && res.state && res.state.onDemandObj) {
  //       setOnDemandScraper(res.state.onDemandObj)
  //     }
  //   })
  //   console.log('sending payload', payload)
  //   // const loginRes = await AuthService.login(data.get('email'), data.get('password'))
  //   // console.log('login res====', loginRes);
  // };
  // const fetchScraperHealth = async () => {
  //   socket.emit('scraper-health', {}, (res) => {
  //     console.log('scraper Health is====', res);
  //   })
  // }
  // React.useEffect(() => {
  //   socket.on('connect', () => {
  //     console.log('socket connected')
  //     setIsConnected(true);
  //   });

  //   socket.on('disconnect', () => {
  //     setIsConnected(false);
  //     console.log('socket connected')
  //   });
  //   const healthInterval = setInterval(() => {
  //     fetchScraperHealth()
  //   }, 5000)
  //   return () => {
  //     socket.off('connect');
  //     socket.off('disconnect');
  //     socket.off('pong');
  //     clearInterval(healthInterval)
  //   };
  // }, []);
  return (
    <p>xoins</p>
    // <ThemeProvider theme={theme}>
    //   <Container component="main" maxWidth="xs">
    //     <CssBaseline />
    //     <Box
    //       sx={{
    //         marginTop: 8,
    //         display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'center',
    //       }}
    //     >
    //       <h1>
    //         Scraper Health: <strong>Free BA</strong>: {scraperHealth && scraperHealth.freeBaAccounts}, <strong>Blocked BA</strong>: {scraperHealth && scraperHealth.blockedBaAccounts}
    //       </h1>
    //       <Avatar sx={{ m: 1, }}>
    //         {/* <PrecisionManufacturingIcon /> */}
    //         {isConnected ? <PrecisionManufacturingIcon /> : <CircularProgress />}
    //       </Avatar>
    //       <Typography component="h1" variant="h5">
    //         Scraper BA
    //       </Typography>
    //       <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
    //         <TextField
    //           margin="normal"
    //           required
    //           fullWidth
    //           id="origin"
    //           label="origin"
    //           name="origin"
    //           autoComplete="ba-am-origin"
    //           autoFocus
    //         />
    //         <TextField
    //           margin="normal"
    //           required
    //           fullWidth
    //           id="destination"
    //           label="destination"
    //           name="destination"
    //           autoComplete="ba-am-destination"
    //           autoFocus
    //         />
    //         <DatePicker
    //           label="Start Date"
    //           value={startDate}
    //           name={startDate}
    //           onChange={(newValue) => {
    //             setStartDate(newValue)
    //           }}
    //           renderInput={(params) => <TextField {...params} />}
    //         />
    //         <DatePicker
    //           label="End Date"
    //           value={endDate}
    //           name="endDate"
    //           onChange={(newValue) => {
    //             setEndDate(newValue)
    //           }}
    //           renderInput={(params) => <TextField {...params} />}
    //         />
    //         <Button
    //           type="submit"
    //           fullWidth
    //           variant="contained"
    //           sx={{ mt: 3, mb: 2 }}
    //           disabled={!isConnected}
    //         >
    //           Sign In
    //         </Button>
    //         {/* <Grid container>
    //           <Grid item xs>
    //             <Link href="#" variant="body2">
    //               Forgot password?
    //             </Link>
    //           </Grid>
    //           <Grid item>
    //             <Link href="#" variant="body2">
    //               {"Don't have an account? Sign Up"}
    //             </Link>
    //           </Grid>
    //         </Grid> */}
    //       </Box>
    //     </Box>
    //     {!!onDemandScraper && <Modal
    //       hideBackdrop
    //       open={!!onDemandScraper}
    //       onClose={() => setOnDemandScraper(null)}
    //       aria-labelledby="child-modal-title"
    //       aria-describedby="child-modal-description"
    //     >
    //       <Box sx={{ ...modalStyle }}>
    //         <OnDemandScrapeDisplay onDemandScraper={onDemandScraper} />
    //         <Button onClick={() => { setOnDemandScraper(null) }}>Close </Button>
    //       </Box>
    //     </Modal>}
    //     {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    //   </Container>
    // </ThemeProvider>
  );
}