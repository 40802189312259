import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'origin', headerName: 'Origin', width: 50 },
  { field: 'destination', headerName: 'destination', width: 70},
  {
    field: 'departDate',
    headerName: 'Depart Date',
    // type: 'number',
    width: 150,
  },
  {
    field: 'departTime',
    headerName: 'Depart Time',
    // type: 'number',
    width: 100,
  },
  {
    field: 'price',
    headerName: 'Price',
    // description: 'This column has a value getter and is not sortable.',
    // sortable: false,
    width: 400,
    // valueGetter: (params) =>
    //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
];

// const flights = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

export default function FlightsTable({flights}) {
  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={flights.map((s, i) => ({...s, id: i}))}
        columns={columns}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        getRowId={(row, x) => row.id}
        checkboxSelection
      />
    </div>
  );
}