import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid, IconButton, List, ListItem, ListItemText, Paper, Popover } from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { momentAgo } from "../../utils";
import SystemMenu from "./SystemMenu";
import { styled } from "@mui/joy/styles";

const MetaTextLine = styled(Typography)(({}) => ({
  
}))
const SystemProgressReport = ({ system }) => {
  // if(!system?.isRunning) {
  //   return null;
  // }
  return (
    <Grid item xs={12} sx={{marginTop: '10px'}}>
      <Divider>Progress</Divider>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          lineHeight: '18px',
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        Total: <span>{system?.attributes?.total || "N/A"}</span>
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          lineHeight: '18px',
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        Success: <span>{system?.attributes?.success || "N/A"}</span>
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          lineHeight: '18px',
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        Failed: <span>{system?.attributes?.failed || "N/A"}</span>
      </Typography>
    </Grid>
  );
};

const SystemInfoBox = ({ system }) => {
  const [visible, setVisible] = useState();

  return (
    <Grid key={system.id} item xs={12} sm={3} style={{display: 'flex'}}>
      <Paper elevation={3} style={{ padding: "10px", width: '100%' }}>
        <Grid container style={{position: 'relative', width: '100%'}}>
          <Grid item style={{width: '100%'}}>
            <Typography fontSize={"16px"} style={{lineHeight: '18px'}} marginBottom={'10px'}>{system.name}</Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: '18px',
                fontWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              Online:{" "}
              {system.isOnline ? (
                <CheckCircleIcon sx={{ color: "green", height: '14px' }} />
              ) : (
                <CancelIcon sx={{ color: "red", height: '14px' }} />
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: '18px',
                fontWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              Running:{" "}
              {system.isRunning ? (
                <CheckCircleIcon sx={{ color: "green",  height: '14px' }} />
              ) : (
                <CancelIcon sx={{ color: "red",  height: '14px' }} />
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: '18px',
                fontWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              Last Run: <span style={{textAlign: 'right'}}>{momentAgo(system.lastRunAt)}</span>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: '18px',
                fontWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              Updated At: <span style={{textAlign: 'right'}}>{momentAgo(system.updatedAt)}</span>
            </Typography>
          </Grid>
            <SystemMenu system={system}/>
        </Grid>
        <Grid container spacing={2}>
          <SystemProgressReport system={system} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default SystemInfoBox;
