import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import useAuth from "../Context/Auth";
import MenuBar from "../Components/Menubar";
import axios, { Axios } from "axios";
import styled from "@emotion/styled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AMCP_API_BASE } from "../constants";
import APIKit from "../services/api";
import SystemInfoBox from "../Components/Dashboard/SystemInfoBox";
import EventsFeed from "../Components/Dashboard/EventsFeed";
import OrcestratorInfoBox from "../Components/Dashboard/OrcestratorInfoBox";
import useReports from "../Context/Reports";

const Dashboard = () => {
  const { user } = useAuth();
  const [events, setEvents] = useState([]);
  
  const {getAllSystemsReport, allSystems} = useReports();
  // const getAllSystemsReport = async () => {
  //   try {
  //     const { data: systemsData, status } = await APIKit.get(
  //       `/api/admin/systems`
  //     );

  //     if (status === 200 && systemsData) {
  //       setAllSystems(systemsData.data.systems.rows);
  //     }
  //   } catch (e) {
  //     setAllSystems([]);
  //     console.log("Error on fetch system report", e.toString());
  //   }
  // };

  // useEffect(() => {
  //   getAllSystemsReport();
  // }, []);

  console.log("our admin user is", user);
  return (
    <div style={{ height: "100vh" }}>
      <MenuBar />

      <Grid container spacing={3} height="100vh" style={{ padding: 20 }}>
        <Grid item xs={12} sm={9} height="100%">
          <AppBar position="static">
            <Toolbar variant="dense">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <DashboardIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" component="div">
                Systems
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid container style={{display: 'flex'}} spacing={1}>
            {allSystems.map((system) => (
              <SystemInfoBox system={system} key={system.id} />
            ))}
          </Grid>
          {/* <Grid container spacing={3} style={{display: 'flex'}}> */}
            <OrcestratorInfoBox />
          {/* </Grid> */}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid item>
            <EventsFeed data={events} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Dashboard;
