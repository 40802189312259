import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  Tooltip,
} from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { momentAgo, momentAgoMasterQueue } from "../../utils";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import { styled } from "@mui/joy/styles";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import BackupIcon from "@mui/icons-material/Backup";
import DeleteIcon from "@mui/icons-material/Delete";
import { style } from "@mui/system";
import APIKit from "../../services/api";
import { enqueueSnackbar } from "notistack";
import OrcestratorMenu from "./OrcestratorMenu";
import { refetchReports } from "../../services/common";
import useReports from "../../Context/Reports";
const RowItem = styled(Sheet)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  fontWeight: "bold",
  color: "black",
  backgroundColor: "transparent",
}));
const Item = styled(Sheet)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  fontWeight: "lighter",
  color: "black",
  //   backgroundColor: 'transparent'
}));
const firstItemStyle = {
  backgroundColor: "#0072bb",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  display: "flex",
  color: "white",
};
const JobItem = ({ ac, showPromote, hideDelete, fetchOrcestratorReport }) => {
  const { fetchAllReports } = useReports();
  const removeJob = async () => {
    try {
      const { data: orcestratorRes } = await APIKit.post(
        `/api/admin/systems/orcestratorAction`,
        {
          type: ac?.data?.jobType,
          action: "REMOVE",
          jobId: ac.id,
        }
      );
      console.log("deleteResponse====", orcestratorRes);
      if (orcestratorRes && orcestratorRes.success) {
        enqueueSnackbar(`Job ${ac?.data?.jobType} #${ac?.id} Removed`);
        fetchAllReports(true);
      } else {
        enqueueSnackbar(
          `Failed to remove Job ${ac?.data?.jobType} #${ac?.id}. ${orcestratorRes?.error}`
        );
      }
      //   fetchOrcestratorReport();
    } catch (e) {
      enqueueSnackbar(`Failed to remove Job ${ac?.data?.jobType} #${ac?.id}}`);
      console.log("Error on fetch system report", e.toString());
    }
  };
  return (
    <Item sx={{ textAlign: "left", display: "flex" }}>
      <div
        style={{ display: "flex", flexDirection: "column", marginRight: "5px" }}
      >
        {ac?.data?.jobType}
        <div>
          <span style={{ color: "grey" }}>Added</span>{" "}
          {momentAgoMasterQueue(ac.timestamp)}
        </div>
        {ac.processedOn && (
          <div>
            <span style={{ color: "grey" }}>Processed</span>{" "}
            {momentAgoMasterQueue(ac.processedOn)}
          </div>
        )}
        {ac.finishedOn && !ac.isFailed && (
          <div>
            <span style={{ color: "grey" }}>Finished</span>{" "}
            {momentAgoMasterQueue(ac.finishedOn)}
          </div>
        )}
        {ac.finishedOn && !!ac.isFailed && (
          <div>
            <span style={{ color: "grey" }}>Failed</span>{" "}
            {momentAgoMasterQueue(ac.finishedOn)}
          </div>
        )}
      </div>
      <Divider orientation="vertical" flexItem />
      <div style={{ display: "flex" }}>
        <ButtonGroup
          orientation="vertical"
          aria-label="vertical contained button group"
          variant="text"
        >
          {!!showPromote && (
            <IconButton key="action1" size="small">
              <Tooltip title="Promotes job to next level">
                <BackupIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          )}
          {!hideDelete && (
            <IconButton key="action2" size="small" onClick={removeJob}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </ButtonGroup>
      </div>
    </Item>
  );
};
const CleanButton = ({ status }) => {
  const { fetchAllReports } = useReports();
  const CleanJobs = async () => {
    try {
      const { data: orcestratorRes } = await APIKit.post(
        `/api/admin/systems/orcestratorAction`,
        {
          // type: ac?.data?.jobType,
          action: "CLEAN",
          status,
        }
      );
      console.log("response clean jobb is====", orcestratorRes);
      if (orcestratorRes && orcestratorRes.success) {
        enqueueSnackbar(`${status} queue cleaned`);
        await fetchAllReports(true);
      } else {
        enqueueSnackbar(`Failed to clean ${status}. ${orcestratorRes?.error}`);
      }
      //   fetchOrcestratorReport();
    } catch (e) {
      enqueueSnackbar(`Failed to clean ${status}`);
    }
  };

  return (
    <Button
      variant="text"
      onClick={CleanJobs}
      sx={{ color: "blue" }}
      size="small"
    >
      Clean
    </Button>
  );
};
const MainQueueJobTypeList = ({ system, fetchOrcestratorReport }) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <RowItem>
        <Stack spacing={2} direction="row" spacing={2} sx={{ width: "100%" }}>
          <Item style={firstItemStyle}>Active</Item>
          {system?.report?.active?.map((ac, i) => (
            <JobItem
              key={`jobType${i}`}
              ac={ac}
              hideDelete
              fetchOrcestratorReport={fetchOrcestratorReport}
            />
          ))}
        </Stack>
      </RowItem>
      <RowItem>
        <Stack spacing={2} direction="row" spacing={2} sx={{ width: "100%" }}>
          <Item style={firstItemStyle}>
            Waiting
            <CleanButton
              fetchOrcestratorReport={fetchOrcestratorReport}
              status="wait"
            />
          </Item>
          {system?.report?.waiting?.map((ac, i) => (
            <JobItem
              key={`jobType${i}`}
              fetchOrcestratorReport={fetchOrcestratorReport}
              ac={ac}
              showPromote={false}
            />
          ))}
        </Stack>
      </RowItem>
      <RowItem>
        <Stack
          spacing={2}
          direction="row"
          spacing={2}
          sx={{ width: "100%", overflow: "scroll" }}
        >
          <Item style={firstItemStyle}>
            Delayed
            <CleanButton
              fetchOrcestratorReport={fetchOrcestratorReport}
              status="delayed"
            />
          </Item>
          {system?.report?.delayed?.map((ac, i) => (
            <JobItem
              key={`jobType${i}`}
              fetchOrcestratorReport={fetchOrcestratorReport}
              ac={ac}
            />
          ))}
        </Stack>
      </RowItem>
      <RowItem>
        <Stack spacing={2} direction="row" spacing={2} sx={{ width: "100%" }}>
          <Item style={firstItemStyle}>
            Completed
            <CleanButton
              fetchOrcestratorReport={fetchOrcestratorReport}
              status="completed"
            />
          </Item>
          {system?.report?.completed?.map((ac, i) => (
            <JobItem
              key={`jobType${i}`}
              fetchOrcestratorReport={fetchOrcestratorReport}
              ac={ac}
            />
          ))}
        </Stack>
      </RowItem>
      <RowItem>
        <Stack spacing={2} direction="row" spacing={2} sx={{ width: "100%" }}>
          <Item style={firstItemStyle}>
            Failed
            <CleanButton
              fetchOrcestratorReport={fetchOrcestratorReport}
              status="failed"
            />
          </Item>
          {system?.report?.failed?.map((ac, i) => (
            <JobItem
              key={`jobType${i}`}
              fetchOrcestratorReport={fetchOrcestratorReport}
              ac={ac}
            />
          ))}
        </Stack>
      </RowItem>
    </Stack>
  );

  //     <List sx={{ width: '100%', height: '300px', bgcolor: 'background.paper' }}>
  //       <ListItem>
  //         {/* <ListItemAvatar>
  //           <Avatar>
  //             <ImageIcon />
  //           </Avatar>
  //         </ListItemAvatar> */}
  //         <ListItemText primary="Photos" secondary="Jan 9, 2014" />
  //       </ListItem>
  //       <ListItem>
  //         {/* <ListItemAvatar>
  //           <Avatar>
  //             <WorkIcon />
  //           </Avatar>
  //         </ListItemAvatar> */}
  //         <ListItemText primary="Work" secondary="Jan 7, 2014" />
  //       </ListItem>
  //       <ListItem>
  //         {/* <ListItemAvatar>
  //           <Avatar>
  //             <BeachAccessIcon />
  //           </Avatar>
  //         </ListItemAvatar> */}
  //         <ListItemText primary="Vacation" secondary="July 20, 2014" />
  //       </ListItem>
  //     </List>
  //   </Box>
};

const SystemReport = ({ system }) => {
  // if(!system?.isRunning) {
  //   return null;
  // }
  const isMain = system.name === "OrcestratorQueue";
  return (
    <Grid item xs={12} sx={{ marginTop: "10px" }}>
      <Divider sx={{ fontSize: "12px" }}>Progress</Divider>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          lineHeight: "15px",
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        Active:{" "}
        <span>
          {(isMain ? system?.report?.active?.length : system?.report?.active) ||
            "N/A"}
        </span>
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          lineHeight: "15px",
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        Completed:{" "}
        <span>
          {(isMain
            ? system?.report?.completed?.length
            : system?.report?.completed) || "N/A"}
        </span>
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          lineHeight: "15px",
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        Failed:{" "}
        <span>
          {(isMain ? system?.report?.failed?.length : system?.report?.failed) ||
            "N/A"}
        </span>
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          lineHeight: "15px",
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        Waiting:{" "}
        <span>
          {(isMain
            ? system?.report?.waiting?.length
            : system?.report?.waiting) || "N/A"}
        </span>
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          lineHeight: "15px",
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        Delayed:{" "}
        <span>
          {(isMain
            ? system?.report?.delayed?.length
            : system?.report?.delayed) || "N/A"}
        </span>
      </Typography>
    </Grid>
  );
};
const OrcestratorSingleItemWrapper = (props) => {
  if (props.isMain) {
    return (
      <Grid container>
        <OrcestratorSingleItem {...props} />
        <Grid item xs={12} sm={9} style={{ backgroundColor: "#E7EBF0" }}>
          <MainQueueJobTypeList {...props} />
        </Grid>
      </Grid>
    );
  }
  if (!props.isMain) {
    return <OrcestratorSingleItem {...props} />;
  }
};
const OrcestratorAddJobView = () => {
  const [newJob, setNewJob] = useState('');
  const systems = ['SYSTEM_FLUSH', 'autocaching', 'EFW', 'AMFU', 'flexible-controller']
  const {fetchAllReports} = useReports();
  const addJob = async () => {
    try {
      const { data: orcestratorRes } = await APIKit.post(
        `/api/admin/systems/orcestratorAction`,
        {
          type: newJob,
          action: "ADD",
          options: {}
        }
      );
      if (orcestratorRes && orcestratorRes.success) {
        enqueueSnackbar(`Job ${newJob} Added`);
        await fetchAllReports(true);
      } else {
        enqueueSnackbar(`Failed to add ${newJob} Added`);
      }
      //   fetchOrcestratorReport();
    } catch (e) {
      console.log("Error adding job", e.toString());
    }
  };
  const setJob = (ev) => {
    setNewJob(ev.target.value)
  };
  return (
    <>
      <FormControl fullWidth sx={{marginTop: '20px'}}>
        <InputLabel id="add-new-job">Add New Task</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={newJob}
          label="Age"
          onChange={setJob}
        >
         {systems.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        {!!newJob.length && <Button variant="outlined" onClick={addJob}>Proceed</Button>}
      </FormControl>
    </>
  );
};
const OrcestratorSingleItem = ({ system: systemProp, orcestrator, isMain, fetchOrcestratorReport }) => {
  const [visible, setVisible] = useState();
  
  const system = systemProp ? systemProp : (isMain ? {
    name: 'Orcestrator',
    isPaused: true,
    isOnline: false,
  } : null)
  if (!system) {
    return null;
  }
  return (
    <Grid key={system.name} item xs={12} sm={3} style={{ display: "flex" }}>
      <Paper elevation={3} style={{ padding: "10px", width: "100%" }}>
        <Grid container spacing={2} style={{ position: "relative" }}>
          <Grid item style={{ width: "100%" }}>
            <Typography variant="h6" fontSize={"14px"}>
              {system.name}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: "15px",
                fontWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              Processing:{" "}
              {!system.isPaused ? (
                <CheckCircleIcon sx={{ color: "green", height: "14px" }} />
              ) : (
                <CancelIcon sx={{ color: "red", height: "14px" }} />
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: "15px",
                fontWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              Online:{" "}
              {orcestrator.isOnline ? (
                <CheckCircleIcon sx={{ color: "green", height: "14px" }} />
              ) : (
                <CancelIcon sx={{ color: "red", height: "14px" }} />
              )}
            </Typography>

            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                lineHeight: "15px",
                fontWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              Updated At:{" "}
              <span style={{ textAlign: "right" }}>
                {momentAgo(orcestrator.updatedAt)}
              </span>
            </Typography>
          </Grid>
          {isMain && <OrcestratorMenu system={system} />}
        </Grid>
        <Grid container>
            <Grid item xs={12}>
              <SystemReport system={system} />
            </Grid>
              {isMain && <OrcestratorAddJobView fetchOrcestratorReport={fetchOrcestratorReport}/>}
        </Grid>
        {/* {isMain && (
          <Grid container spacing={2}>
            
          </Grid>
        )} */}
      </Paper>
    </Grid>
  );
};

export default OrcestratorSingleItemWrapper;
