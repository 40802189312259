import React from "react";
import { Route, Redirect, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import useAuth from "../Context/Auth";
import AuthService from "../services/auth";
const cookies = new Cookies();

// receives component and any other props represented by ...rest
export default function ProtectedRoutes({ children, ...rest }) {
    const {user} = useAuth();
    const isAuthenticate = user?.token;
    if (isAuthenticate) {
        return children
    } else {
        console.log('no token')
        return (
            <Navigate
                //   state={{
                //     s:"ss"
                //   }}
                to={{
                    pathname: "/login",
                    state: {
                        // sets the location a user was about to assess before being redirected to login
                        from: "props.location",
                    },
                }}
            />
        );
    }
}
