import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { IconButton, Tooltip } from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import Axios from "axios";
import { enqueueSnackbar } from "notistack";
import APIKit from "../../services/api";

const SystemMenu = ({ system }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { host, stopEndpoint, apiSecret } = system;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const systemAction = async (method, action) => {
    try {
      const confirmed = window.confirm('Are you sure ?')
      if(!confirmed) {
        return;
      }
      if (!action) {
        enqueueSnackbar("No action on system");
        return;
      }
      const { data } = await APIKit.post(`/api/admin/systems/${system.id}/action`, {
        actionName: action,
        actionMethod: method,
      })
      enqueueSnackbar(`System ${action} initiated. It might take some time to reflect`);
    } catch (e) {
      enqueueSnackbar(`Error: ${e.toString()}`);
      console.log("Error on asysction system", e.toString(), e.request);
    }
  };

  return (
    <div style={{position: 'absolute', top: 0, right: '10px'}}>
      <Tooltip title="Actions" placeholder="top" arrow>
        <IconButton
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <GridMoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        style={{ padding: 10 }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            systemAction("post", "start");
          }}
        >
          Start
        </MenuItem>
        <MenuItem
          onClick={() => {
            systemAction("post", "stop");
          }}
        >
         Stop
        </MenuItem>
      </Menu>
    </div>
  );
};
export default SystemMenu;
