import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useContext, useState } from 'react';
import APIKit from '../../services/api';
import { refetchReports } from '../../services/common';
import useAuth from '../Auth';

const ReportsContext = React.createContext();

export const useAppContext = () => {
  return useContext(ReportsContext);
};

export const ReportsContextProvider = props => {
  const [orcestrator, setOrcestratorReport] = useState([]);
  const [allSystems, setAllSystems] = useState([]);
  const [loading, setLoading] = useState(false);
  const {user} = useAuth();

  const fetchOrcestratorReport = async () => {
    try {
      const { data: orcestratorRes } = await APIKit.get(
        `/api/admin/systems/orcestrator`
      );
      console.log("reort=======", orcestratorRes);
      if (orcestratorRes?.success && orcestratorRes?.data?.orcestratorReport) {
        setOrcestratorReport(orcestratorRes.data.orcestratorReport);
      } else {
        enqueueSnackbar(
          orcestratorRes?.error || "Failed to fetch orcestrator report"
        );
      }
    } catch (e) {
      setOrcestratorReport(null);
      console.log("Error on fetch system report", e.toString());
    }
  };

  const fetchAllSystemReports = async () => {
    try {
      const { data: systemsData, status } = await APIKit.get(
        `/api/admin/systems`
      );

      if (status === 200 && systemsData) {
        setAllSystems(systemsData.data.systems.rows);
      }
    } catch (e) {
      setAllSystems([]);
      console.log("Error on fetch system report", e.toString());
    }
  };

  const fetchAllReports = async (refetchBackend = false, handleLoading = true) => {
    console.log('Called 1')
    handleLoading && setLoading(true);
    if(refetchBackend) {
      await refetchReports();
    }
    await Promise.all([fetchOrcestratorReport(), fetchAllSystemReports()]);
    handleLoading && setLoading(false);
  }

  useEffect(() => {
    fetchAllReports();
    const fetchInterval = setInterval(() => {
      fetchAllReports(false, false)
    }, 30 * 1000);

    return () => {
      clearInterval(fetchInterval);
    }
  }, []);
  return (
    <ReportsContext.Provider
      value={{
        orcestrator,
        allSystems,
        fetchOrcestratorReport,
        fetchAllSystemReports,
        fetchAllReports,
        loading
      }}>
      {props.children}
    </ReportsContext.Provider>
  );
};

export default ReportsContext;