import logo from "./logo.svg";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { Grid, Portal } from "@mui/material";
import AdhocScrape from "./Screens/AdhocScrape";
import Home from "./Screens/Home";
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import Login from "./Screens/Login";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import OnDemandScrapeDisplay from "./Components/OnDemandScrapeDisplay";
import AdminRoute from "./Routes/AdminRoute";
import Dashboard from "./Screens/Dashboard";
import { AuthContextProvider } from "./Context/Auth/AuthContext";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { ReportsContextProvider } from "./Context/Reports/ReportsContext";
import { Spin } from "antd";
import useReports from "./Context/Reports";
const WithSpinner = () => {
  const { loading } = useReports();
  return (
    <Spin spinning={loading}>
      <Grid>
        <Routes>
          <Route exact path="/" element={
              <AdminRoute>
                <Dashboard />
              </AdminRoute>
            } />
          <Route exact path="/login" element={<Login />} />
          {/* <Route exact path="/free" component={FreeComponent} /> */}
          <Route
            path="/adhoc-scrape"
            element={
              <ProtectedRoutes>
                <AdhocScrape />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/onDemandScraper/:id"
            element={
              <ProtectedRoutes>
                <OnDemandScrapeDisplay />
              </ProtectedRoutes>
            }
          />
          <Route
            exact
            path="/admin/dashboard"
            element={
              <AdminRoute>
                <Dashboard />
              </AdminRoute>
            }
          />
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </Grid>
    </Spin>
  );
};
function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <SnackbarProvider>
        <Container maxWidth disableGutters>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AuthContextProvider>
              <ReportsContextProvider>
                <WithSpinner />
              </ReportsContextProvider>
            </AuthContextProvider>
          </LocalizationProvider>
        </Container>
      </SnackbarProvider>
    </React.Fragment>
  );
}

export default App;
