import React from "react";
import { Route, Redirect, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import useAuth from "../Context/Auth";
const cookies = new Cookies();

// receives component and any other props represented by ...rest
export default function AdminRoute({ children, ...rest }) {
    const {user} = useAuth();
    console.log('user is===', user)
    const isAuthenticate = user && user?.user?.isAdmin || false;

    if (isAuthenticate) {
        return children
    } else {
        console.log('no admin')
        if(user){
            // todo
            return null
        }else{
        return (
            <Navigate
                //   state={{
                //     s:"ss"
                //   }}
                to={{
                    pathname: "/login",
                    state: {
                        // sets the location a user was about to assess before being redirected to login
                        from: "props.location",
                    },
                }}
            />
        );
        }
    }
}
