import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ReactJson from 'react-json-view';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Modal } from 'antd';
import {
  AppBar,
  Badge,
  IconButton,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import APIKit from "../../services/api";
import { momentAgo } from "../../utils";
import moment from "moment";
import { Box } from "@mui/system";
const groupEventMessages = (_events = []) => {
  const events = {};
  _events.map((s, i) => {
    const signature = `${s.systemId}-${s.message}-${
      s.isRunning ? "RUNNING" : "NORUNNING"
    }`;
    if (!events[signature]) {
      events[signature] = {
        ...s,
        count: 1,
        latestAt: s.createdAt,
        firstAt: s.createdAt,
      };
    } else {
      events[signature].count += 1;
      events[signature].firstAt =
        moment(s.createdAt).diff(events[signature]) < 0
          ? events[signature].createdAt
          : s.createdAt;
      events[signature].createdAt =
        moment(s.createdAt).diff(events[signature]) > 0
          ? events[signature].createdAt
          : s.createdAt;
    }
  });
  return Object.values(events);
};
const SingleEvent = ({event}) => {
    const [showDetails, setShowDetails] = React.useState(false);
    return  <React.Fragment key={event.id}>
            <ListItem alignItems="flex-start"  style={{flexDirection: 'column'}} secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => setShowDetails(!showDetails)}>
                      <RemoveRedEyeIcon/>
                    </IconButton>
                  }>
              {/* <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar> */}
              <ListItemText
                primary={
                  <Badge badgeContent={event?.count || 0} color="error">
                    {event?.System?.name || "Info"}
                  </Badge>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline", lineHeight: "14px" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {event?.message}
                    </Typography>
                    " — "{momentAgo(event.createdAt)}
                    {!!event?.count && !!event.firstAt && (
                      <Tooltip title="FirstSeen" placeholder="top" arrow>
                        <span>
                            {" "}
                        | {momentAgo(event.firstAt)}
                        </span>
                      </Tooltip>
                    )}
                  </React.Fragment>
                }
              />
              
            </ListItem>
            <Divider component="li" />
            <Modal open={showDetails} title="Event" onCancel={() => {setShowDetails(false)}} onOk={() => setShowDetails(false)}>
               <ReactJson
               name={false}
                iconStyle={'triangle'}
                theme={'monokai'}
                displayObjectSize={false}
                displayDataTypes={false}
                collapseStringsAfterLength={1000}
                src={event.response ? event.response : {}}
              />
            </Modal>
          </React.Fragment>
}

export default function AlignItemsList() {
  const [events, setEvents] = React.useState([]);
  const [rawEvents, setRawEvents] = React.useState([]);
  const [showAll, setShowAll] = React.useState(false);
  const getEvents = async () => {
    try {
      const { data: systemHistoryData, status } = await APIKit.get(
        `/api/admin/systems/history?onlyEvents=true&limit=500`
      );

      if (status === 200 && systemHistoryData) {
        console.log(
          "evebts====000000",
          groupEventMessages(systemHistoryData.data.rows)
        );
        setRawEvents(systemHistoryData.data.rows)
        setEvents(groupEventMessages(systemHistoryData.data.rows));
      }
    } catch (e) {
      setEvents([]);
      setRawEvents([])
      console.log("Error on fetch system report", e.toString());
    }
  };
  React.useEffect(() => {
    getEvents();
  }, []);
  return (
    <div style={{ height: 700, overflow: "scroll" }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <CalendarMonthIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" component="div">
            Events
          </Typography>
          <IconButton onClick={() => {setShowAll(!showAll)}}>
            <Typography fontSize="14px" color="inherit" component="div">
            Show {showAll ? 'Grouped' : 'All'}
          </Typography>
          </IconButton>
        </Toolbar>
      </AppBar>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {(showAll ? rawEvents : events).map((event) => <SingleEvent key={event.id} event={event}/>)}
      </List>
    </div>
  );
}
