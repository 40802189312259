import axios from "axios";
import Cookies from "universal-cookie";
import { BASE_URL } from "../constants";

const cookies = new Cookies();

const register = (username, email, password) => {
  return axios.post(BASE_URL + "/signup", {
    username,
    email,
    password,
  });
};

const login = (email, password) => {
  console.log("calling---",email, password)
  return axios
    .post(BASE_URL + "/pub/login", {
      email,
      password,
    })
    .then(({ data: response }) => {
        console.log('loginres==', response)
      if (response.data.token) {
        // localStorage.setItem("user", JSON.stringify(response.data));
        //  cookies.set("TOKEN", response.data.token, {
        //   path: "/",
        // });

        return {
            success: true,
            user: response.data
        }
      }
      return {
        success: false,
        error: (response && response.errorMessage) || 'Failed to login'
      }
    }).catch(e => {
        return {
            success: false,
            error:  (e.response && e.response.data && e.response.data.errorMessage) || 'Failed to login'
        }
    });
};

const logout = (sendHome = false) => {
  localStorage.removeItem("user-AM");
  if(sendHome) {
    // window.location.href = '/';
  }
  // return axios.post(BASE_URL + "signout").then((response) => {
  //   return response.data;
  // });
};

const getCurrentUser = () => {
  try {
    return JSON.parse(localStorage.getItem("user-AM"));
  } catch(e) {
    return null;
  }
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
}

export default AuthService;