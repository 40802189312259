import { Avatar, CircularProgress, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIKit from "../services/api";
import FlightsTable from "./FlightsTable";
// import io from 'socket.io-client';
import { BASE_URL } from "../constants";
// const socket = io(BASE_URL);
export default function OnDemandScrapeDisplay({ scrapeIdProp, id: propId, onDemandScraper: onDemandScraperProp }) {
    // const { id: paramId } = useParams()
    // const [logs, setLogs] = React.useState([])
    // const [isConnected, setIsConnected] = React.useState(socket.connected);
    // const [lastPong, setLastPong] = React.useState(null);
    // const [socketInitiated, setSocketInitiated] = useState(false)
    // const id = propId || paramId || (onDemandScraperProp && onDemandScraperProp.id);
    // const [status, setStatus] = useState();
    // const [flights, setFlights] = useState([])
    // const fetchOnDemandScraper = async () => {
    //     try {
    //         const { data } = await APIKit.get(`/api/onDemandScraper/${id}`);
    //         console.log('fetched scraper', data)
    //         if (data && data.code === 200) {
    //             setOnDemandScraper(data.data)
    //             setFlights(data.data.flights || [])
    //         }
    //     } catch (e) {
    //         alert('Error fetching scraper ' + e.toString())
    //     }

    // }
    // const [onDemandScraper, setOnDemandScraper] = useState(onDemandScraperProp);
    // React.useEffect(() => {
    //     if (onDemandScraper && !socketInitiated) {
    //         setSocketInitiated(true)
    //         socket.on('connect', () => {
    //             console.log('socket connected')
    //             setIsConnected(true);
    //         });

    //         socket.on('disconnect', () => {
    //             setIsConnected(false);
    //             console.log('socket connected')
    //         });

    //         socket.on('pong', () => {
    //             setLastPong(new Date().toISOString());
    //             console.log('socket pong')
    //         });
    //         socket.on('scrape-update', (rrr) => {
    //             console.log('scrape-update', rrr);
    //         })
    //         console.log('Socket listening to', `ondemand-${onDemandScraper.scrapeId}`)
    //         socket.on(`ondemand-${onDemandScraper.scrapeId}`, (res) => {
    //             console.log('Scraping completed');
    //             switch(res.type) {
    //                 case 'scrape-update':
    //                     break;
    //                 case 'scrape-results':
    //                     if(res.error) {
    //                         setLogs(prev => ([...prev, `Scraper Completed with Error: ${res.error}`]))
    //                     } else if(res.data) {
    //                         const d = JSON.parse(res.data)
    //                         if(d.status) {
    //                             setStatus(d.status)
    //                             setLogs(prev => ([...prev, `StatusChanged: ${d.status}`]))
    //                         }
    //                         if(d.flights) {
    //                             setFlights(prev => ([...prev, flights]))
    //                         }
    //                     }
    //                     break;
    //                 default:
    //                     console.log('Unhandled onDemand event', res)
    //                     break;
    //             }
    //             if (res.error) {
    //                 console.log('Error', res.error)
    //                 setLogs(prev => ([...prev, `Scraper Completed with Error: ${res.error}`]))
    //             } else {
    //                 setLogs(prev => ([...prev, `ScraperCompleted`]))
    //             }
    //             console.log('Res', res)
    //         })
    //         console.log('socket', socket);
    //     }
    //     return () => {
    //         socket.off('connect');
    //         socket.off('disconnect');
    //         socket.off('pong');
    //     };
    // }, [onDemandScraper]);
    // useEffect(() => {
    //     if (!id) {
    //         alert('Missing Id');
    //     } else {
    //         if (!onDemandScraper) {
    //             fetchOnDemandScraper();
    //         }
    //     }
    // }, []);
    // if (!onDemandScraper) {
    //     return (<React.Fragment>
    //         <Avatar sx={{ m: 1, }}>
    //             {/* <PrecisionManufacturingIcon /> */}
    //             <CircularProgress />
    //         </Avatar>
    //     </React.Fragment>)
    // }

    // const { origin, destination, scrapeId, type, startDate, endDate } = onDemandScraper
    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <p>xoina</p>
                {/* <Grid container>
                    <Grid item xs={4} style={{ padding: 5 }}>
                        <Paper elevation={2}>
                            <p id="child-modal-title">ScrapeId: {origin}-{destination} id: {scrapeId}</p>
                            <p id="child-modal-description">
                                StartDate: {startDate}
                            </p>
                            <p id="child-modal-description">
                                TotalFlights: {flights.length}
                            </p>
                            <p id="child-modal-description">
                                Status: {status}
                            </p>
                            {logs.map((s, k) => <p key={k}>{s}</p>)}
                        </Paper>

                    </Grid>
                    <Grid item xs={8} style={{ padding: 10 }}>
                        <Paper elevation={2}>
                            <FlightsTable flights={flights} />
                        </Paper>
                    </Grid>
                </Grid> */}
            </Box>
        </React.Fragment>
    );
}