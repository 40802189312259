import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import Cookies from "universal-cookie";
import { BASE_URL } from '../constants';
import AuthService from './auth';
const cookies = new Cookies();

// Create axios client, pre-configured with baseURL
let APIKit = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});
// enqueueSnackbar
APIKit.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('mula error', error);
    let message = error.response?.data?.errorMessage || error?.message;
    if(message.includes('INVALID_TOKEN')) { // handle logout
      AuthService.logout(true)
      message += '. Logging out';
    }
    enqueueSnackbar(`[API_ERROR] ${message}`)
    return Promise.reject(error)
  });
APIKit.interceptors.request.use(function(config) {
  const user =  AuthService.getCurrentUser();
  console.log('intercepted user is', user);
  if(user) {
    config.headers.Authorization = `Bearer ${user.token}`;
  }
    return config;
  });


export default APIKit;