import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import APIKit from '../services/api';
import { Avatar, Card, Grid, Paper } from '@mui/material';
import * as moment from 'moment';
import TimeAgo from 'timeago-react';
import { momentAgo } from '../utils';
import LockOutlined from '@mui/icons-material/LockOutlined';
import Person from '@mui/icons-material/Person';
import useReports from '../Context/Reports';
import useAuth from '../Context/Auth';

const SingleNotification = ({ nf, setNotificationRead }) => {
  const color = React.useMemo(() => {
    switch(nf.severity) {
      case 'error':
        return 'red';
      case 'info':
        return 'green';
      case 'warn':
        return 'yellow';
      default:
        return 'white';
    }
  }, [
    nf.severity
  ])
  return (
    <MenuItem style={{ padding: 0, paddingBottom: 5 }} onClick={() => setNotificationRead(nf.id)}>
      <Card style={{ width: 300, padding: 10, borderRight: `5px solid ${color}`}}>
        <Grid container>
          <Grid item sx={{display: "flex"}}>
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <Person />
            </Avatar>
             
             <Typography
             whiteSpace={'break-spaces'}
              sx={{ fontSize: 14 }}
              fontWeight={nf.isRead ? 'normal' : 'bold'}
              color="text.secondary"
              gutterBottom
            >
              <Badge badgeContent={(nf.count && nf.count > 1) ? nf.count : 0} color="error">
              {momentAgo(nf.createdAt)}
             </Badge>
               <Typography
              sx={{ fontSize: 14 }}
              fontWeight={nf.isRead ? 'normal' : 'bold'}
              color="text.primary"
              gutterBottom
            >
             {nf.title || ''}
            </Typography>             
            </Typography>
          </Grid>
          <Grid item>
            <Typography whiteSpace={'break-spaces'} variant="body2" fontSize={"12px"} fontWeight={nf.isRead ? 'normal' : 'bold'}>{nf.message}</Typography>
          </Grid>
        </Grid>
      </Card>
    </MenuItem>
  );
};
const MenuBar = () =>{
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationAnchorEl, setNotificatonAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const { user, logout } = useAuth();
  const isNotificationOpen = Boolean(notificationAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { loading } = useReports();
  const fetchNotifications = async() => {
    try{
      const {data: notificationData, status} = await APIKit.get(`/api/admin/notifications`);
      setNotifications(notificationData?.data?.rows || [])
    }catch(e){
      setNotifications([])
      console.log("Error on fetch system report",e.toString())
    }
  }

  const setNotificationRead = async(nid) => {
    try{
      const {data: notificationData} = await APIKit.post(`/api/admin/notifications/${nid}/markread`);
      // setNotifications(notificationData?.data?.rows || [])
      fetchNotifications()
    }catch(e){
      setNotifications([])
      console.log("Error on fetch system report",e.toString())
    }
  }
  console.log(notifications);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleNotificationClose = () => {
    setNotificatonAnchorEl(null);
  };

   const handleNotificationIconClick = (event) => {
    setNotificatonAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  // console.log('user is', user);
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => {}}>{user?.user?.firstName} ({user?.user?.isAdmin ? 'Admin' : 'User'})</MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const NotificationList = () => {
    return (
      <Menu
        style={{maxHeight: 600}}
        open={isNotificationOpen}
        keepMounted
        anchorEl={notificationAnchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {notifications.map((nf) => <SingleNotification key={nf.id} nf={nf} fetchNotifications={fetchNotifications} setNotificationRead={setNotificationRead}/>)}
      </Menu>
    );
  };
  React.useEffect(() => {
    fetchNotifications();
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{background:'#808080'}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            AMCP
            {loading ? '...' : ''}
          </Typography>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={0} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              onClick={handleNotificationIconClick}
              color="inherit"
            >
              <Badge badgeContent={notifications.filter(s => !s.isRead).length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
        <NotificationList anchorEl={notificationAnchorEl} onClose={handleNotificationClose} />
      </AppBar>
      {/* {renderMobileMenu} */}
      {renderMenu}
    </Box>
  );
}
export default MenuBar;