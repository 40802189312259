import React, { useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Popover,
  Toolbar,
} from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { momentAgo } from "../../utils";
import SystemMenu from "./SystemMenu";
import APIKit from "../../services/api";
import { enqueueSnackbar } from "notistack";
import DashboardIcon from "@mui/icons-material/Dashboard";
import OrcestratorSingleItem from "./OrcestratorSingleItem";
import DropdownX from "../DropdownX";
import useReports from "../../Context/Reports";
const OrcestratorInfoBox = ({ system }) => {
  // const [orcestrator, setOrcestrator] = useState(null);
  const {orcestrator, fetchOrcestratorReport} = useReports()
  console.log('ako orcestrator', orcestrator);
  // const fetchOrcestratorReport = async () => {
  //   try {
  //     const { data: orcestratorRes } = await APIKit.get(
  //       `/api/admin/systems/orcestrator`
  //     );
  //     console.log("reort=======", orcestratorRes);
  //     if (orcestratorRes?.success && orcestratorRes?.data?.orcestratorReport) {
  //       setOrcestrator(orcestratorRes.data.orcestratorReport);
  //     } else {
  //       enqueueSnackbar(
  //         orcestratorRes?.error || "Failed to fetch orcestrator report"
  //       );
  //     }
  //   } catch (e) {
  //     setOrcestrator(null);
  //     console.log("Error on fetch system report", e.toString());
  //   }
  // };

  // useEffect(() => {
  //   // fetchOrcestratorReport();
  // }, []);
  const mainSystem = React.useMemo(() => orcestrator?.queueReports?.find(s => s.name === 'OrcestratorQueue'), [orcestrator]);
  const otherSystems = React.useMemo(() => orcestrator?.queueReports?.filter(s => s.name !== 'OrcestratorQueue') || [], [orcestrator]);
  
  console.log('mainnnnn', mainSystem, otherSystems)
  if (!orcestrator) {
    return null;
  }
  return (
    <Grid container sx={{ marginTop: "20px" }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <DashboardIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" component="div">
            Orcestrator
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container style={{display: 'flex'}} rowSpacing={1} marginTop="10px">
         <OrcestratorSingleItem system={mainSystem} orcestrator={orcestrator} isMain fetchOrcestratorReport={fetchOrcestratorReport}/>
      </Grid>
      <Grid container style={{display: 'flex'}} spacing={1} marginTop="10px">
         {otherSystems.map((system) => (
        <OrcestratorSingleItem system={system} orcestrator={orcestrator} key={system.name} fetchOrcestratorReport={fetchOrcestratorReport}/>
      ))}
      </Grid>
    </Grid>
  );
};

export default OrcestratorInfoBox;
