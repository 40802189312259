import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { IconButton, Tooltip } from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import Axios from "axios";
import { enqueueSnackbar } from "notistack";
import APIKit from "../../services/api";

const OrcestratorMenu = ({ system }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { host, stopEndpoint, apiSecret } = system;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const queueAction = async (queueName, action) => {
    try {
      const { data: orcestratorRes } = await APIKit.post(
        `/api/admin/systems/orcestratorAction`,
        {
          name: queueName,
          action: action
        }
      );
      console.log("deleteResponse====", orcestratorRes);
      if (orcestratorRes && orcestratorRes.success) {
        enqueueSnackbar(`Queue ${queueName} ${action} done`);
      } else {
        enqueueSnackbar(`Queue ${queueName} ${action} failed`);
      }
      //   fetchOrcestratorReport();
    } catch (e) {
      
      console.log("Error on queue action", e.toString());
    }
  };

  return (
    <div style={{position: 'absolute', top: 0, right: '10px'}}>
      <Tooltip title="Actions" placeholder="top" arrow>
        <IconButton
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <GridMoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        style={{ padding: 10 }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            queueAction("MASTER", "PAUSE");
          }}
        >
          Pause
        </MenuItem>
        <MenuItem
          onClick={() => {
            queueAction("MASTER", "RESUME");
          }}
        >
         Resume
        </MenuItem>
         <MenuItem
          onClick={() => {
            const confirmed = window.confirm('This will stop all running services and flush system. Are you sure ?')
            if(confirmed) {
              queueAction("MASTER", "FLUSH");
              enqueueSnackbar('Flushing system cancelled in progress')
            } else {
              enqueueSnackbar('Flushing system cancelled')
            }
          }}
        >
         Flush
        </MenuItem>
      </Menu>
    </div>
  );
};
export default OrcestratorMenu;
