import React, { useEffect, useContext, useState } from 'react';

const AuthContext = React.createContext();

export const useAppContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = props => {
  const [user, setUser] = useState();

  const _setUser = (_user) => {
    console.log("updating----",_user)
    try {
      window.localStorage.setItem("user-AM",JSON.stringify(_user));
      setUser(_user)
    } catch(e) {
      console.log('Set user error', e);
      window.localStorage.setItem("user-AM", null);
      setUser(null)
    }
  }
    const logout = () => {
    try {
      window.localStorage.setItem("user-AM",null);
      setUser(null)
    } catch(e) {
      console.log('Set user error', e);
      window.localStorage.setItem("user-AM", null);
      setUser(null)
    }
  }

  useEffect(() => {
    console.log("use effect callin")
    const user = window.localStorage.getItem("user-AM");
    if(user && user !== 'null') {
        setUser(JSON.parse(user));
    } else {
      setUser(null);
    }
  }, []);
  console.log('tyo====', typeof user)
  return (
    <AuthContext.Provider
      value={{
        user,
        setUser: _setUser,
        logout,
      }}>
      {typeof user !== 'undefined' && props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;