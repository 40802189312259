import * as moment from 'moment';


export const momentAgo = (date) => {
    try {
        if(!date) {
            return 'N/A';
        }
        if(moment().isSame(date, 'day')) {
            return moment(date).fromNow();
        } else {
            return moment(date).calendar(null, {
            // sameDay: 'hh:mm',
            lastDay: '[Yesterday] HH:mm',
            lastWeek: 'lll',
            sameElse: 'lll'
        })
        }
    } catch(e) {
        return 'N/A';
    }
}
export const momentAgoMasterQueue = (date) => {
    try {
        if(!date) {
            return 'N/A';
        }
       return moment(date).calendar(null, {
            sameDay: 'hh:mm',
            lastDay: 'MM-DD HH:mm',
            lastWeek: 'MM-DD HH:mm',
            sameElse: 'MM-DD HH:mm'
        })
    } catch(e) {
        return 'N/A';
    }
}